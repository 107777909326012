import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import {
  Layout,
  Slice,
  CategoryGrid,
  PostGrid,
  BlogPostSideBar,
  BlogPostHeader,
  TableOfContents,
  Image,
  Breadcrumbs,
} from 'components';
import { Search, ProductGrid } from 'slices';
import './blog_post.scss';

const BlogPost = ({ location, data }) => {
  // data
  const { blogPost, relatedPosts = {}, recentPosts = {}, footerCta = {} } = data;

  const {
    data: pageData = {},
    uid,
    first_publication_date: publishedDate,
    last_publication_date: updatedDate,
  } = blogPost;

  const { nodes: filteredPosts = [] } = relatedPosts;
  const { nodes: newPosts = [] } = recentPosts;
  const hasNewPosts = newPosts.length >= 3;

  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    title,
    hero_image: heroImage,
    category,
    type,
    timestamp,
    author,
  } = pageData;

  const formatType = type === 'short-form' ? 'short-form' : 'long-form';
  const anchorPoints = [];
  // const sideBarCta = options.find(option => option.cta_type === ctaType);

  const { data: ctaData = {} } = footerCta;
  const { body = [] } = ctaData;
  const searchSliceData = body.find((slice) => slice.__typename === 'PrismicSettingsDataBodySearch');

  sliceData.forEach((slice) => {
    const { slice_type: sliceType } = slice;
    if (sliceType === 'anchor_point') {
      if (!slice?.primary?.exclude_tac) {
        anchorPoints.push(slice?.primary?.anchor_title?.text);
      }
    }
  });

  // seo
  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    article: true,
    publishedDate,
    updatedDate,
  };

  // render
  return (
    <Layout location={location} seo={seo}>
      <div className="blog-post">
        <div className="wrapper">
          <BlogPostHeader title={title} category={category} timestamp={timestamp} author={author} />
          <section className="blog-post-image">
            <Image image={heroImage} className="blog-post-hero-image" />
          </section>
          <Breadcrumbs
            noHomeLink
            parentName="Blog"
            parentUid="blog"
            currentPageName={title?.text}
            currentPageUid={uid}
          />
          <div className={`blog-post-content-container ${formatType}`}>
            {type === 'long-form' && <TableOfContents anchorPoints={anchorPoints} />}
            <div className="blog-post-slice-content">
              {sliceData.map((slice) => (
                <Slice key={slice.id} data={{ ...slice, uid }} location={location} />
              ))}
            </div>
            {type === 'short-form' && (
              <BlogPostSideBar filteredPosts={filteredPosts} location={location} title={title} />
            )}
          </div>
        </div>
        <CategoryGrid />
        <ProductGrid />
        {hasNewPosts && <PostGrid posts={newPosts} className="recent-posts" title="Recent Posts" viewAll />}
      </div>
      {searchSliceData && <Search data={searchSliceData} />}
    </Layout>
  );
};

export default withPrismicPreview(BlogPost);

export const blogPostQuery = graphql`
  query BlogPostBySlug($uid: String!, $category: String) {
    blogPost: prismicBlogPost(uid: { eq: $uid }) {
      _previewable
      id
      uid
      prismicId
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        title {
          text
          html
        }
        hero_image {
          url
          gatsbyImageData(layout: CONSTRAINED, width: 1600, outputPixelDensities: 2)
        }
        type
        category {
          id
          uid
        }
        timestamp(formatString: "Do MMM yyyy")
        author {
          text
          html
        }
        body {
          ...BlogPostWysiwyg
          ...BlogPostAnchorPoint
          ...BlogPostBreakoutBox
          ...BlogPostYouTubeVideo
          ...BlogPostImage
          ...BlogPostBlockQuote
        }
      }
    }
    relatedPosts: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: $category } } }, uid: { ne: $uid } }
      limit: 3
    ) {
      nodes {
        uid
        id
        data {
          category {
            uid
          }
          title {
            html
            text
          }
          hero_image {
            url
            gatsbyImageData(layout: CONSTRAINED, width: 400, outputPixelDensities: 2)
          }
        }
      }
    }
    recentPosts: allPrismicBlogPost(
      limit: 3
      filter: { uid: { ne: $uid } }
      sort: { order: ASC, fields: last_publication_date }
    ) {
      nodes {
        uid
        data {
          category {
            uid
          }
          hero_image {
            url
            gatsbyImageData(layout: CONSTRAINED, width: 400, outputPixelDensities: 2)
          }
          title {
            text
            html
          }
        }
      }
    }
    footerCta: prismicSettings {
      data {
        body {
          ... on PrismicSettingsDataBodySearch {
            id
            primary {
              background_colour
              center_content
              content {
                html
                text
              }
              cta_link {
                url
              }
              cta_text {
                html
                text
              }
              exclude_search
              tagline {
                html
                text
              }
              tagline_icon {
                url
              }
              title {
                html
                text
              }
            }
            slice_type
            items {
              feature {
                html
                text
              }
            }
          }
        }
      }
    }
  }
`;
